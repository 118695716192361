import * as THREE from "../../../threejs/build/three.module";
import {Target} from "./target";
import {VertexManager} from "./vertexManager";
import {ParticleMesh} from "./particle-mesh";
import {LineMesh} from "./line-mesh";

export class MorphLogo extends THREE.Object3D {
	#MAX_VERTEX_NUM = 500; //パーティクルの数
	#MAX_CONNECTION = 10; //一つの点が他の点と接続する最大数
	#DISTANCE = 120;
	#R = 1500; //パーティクルを動かす空間の半径

	#vertexManager;
	#particleMesh;
	#lineMesh;
	#timeDelta = 0;

	constructor() {
		super();

		//デバイスによってパーティクルの数を調整
		const htmlTag = document.querySelector("html");
		const isSmartPhone = htmlTag.classList.contains("sp");
		const isTablet = htmlTag.classList.contains("tb");
		if (isSmartPhone) {
			this.#MAX_VERTEX_NUM = 300;
		} else if (isTablet) {
			this.#MAX_VERTEX_NUM = 400;
		}

		//頂点とターゲット座標を作成
		this.#vertexManager = new VertexManager(this.#MAX_VERTEX_NUM, this.#R);

		//頂点メッシュを作成
		this.#particleMesh = new ParticleMesh(this.#MAX_VERTEX_NUM);
		this.add(this.#particleMesh);

		//線メッシュを作成
		this.#lineMesh = new LineMesh(this.#MAX_VERTEX_NUM);
		this.add(this.#lineMesh);
	}

	/**
	 * 描画の更新
	 */
	update() {
		let vertexPos = 0;
		let colorPos = 0;
		let lineCount = 0;

		for (let i = 0; i < this.#MAX_VERTEX_NUM; i++) {
			const vertexA = this.#vertexManager.vertexes[i];
			vertexA.numConnection = 0;
			const targetA = this.#vertexManager.targets[i];

			//頂点を移動する
			targetA.update(this.#timeDelta);
			const isArrived = vertexA.arrive(targetA.position);
			vertexA.update();
			if (isArrived && targetA.mode === Target.MODE_SHOOTING_STAR) {
				//到着したら位置を反対側に移動する
				const v = new THREE.Vector3(vertexA.position.x, vertexA.position.y, vertexA.position.z);
				v.normalize().multiplyScalar(-1);
				const a = new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);
				a.normalize().multiplyScalar(0.5);
				v.add(a);
				v.normalize().multiplyScalar(this.#R);
				vertexA.position.set(v.x, v.y, v.z);
			}

			//パーティクルの座標を更新
			this.#particleMesh.positions[i * 3] = vertexA.position.x;
			this.#particleMesh.positions[i * 3 + 1] = vertexA.position.y;
			this.#particleMesh.positions[i * 3 + 2] = vertexA.position.z;

			//接続する最大数の制限
			if (vertexA.numConnection > this.#MAX_CONNECTION) {
				continue;
			}

			//線の座標を更新
			for (let j = i + 1; j < this.#MAX_VERTEX_NUM; j++) {
				const vertexB = this.#vertexManager.vertexes[j];
				const targetB = this.#vertexManager.targets[j];

				//接続する最大数の制限
				if (vertexB.numConnection > this.#MAX_CONNECTION) {
					continue;
				}

				//ロゴ表示のとき、線を繋げない座標の組み合わせ
				if ((targetA.basePosition.x === -119 && targetB.basePosition.x === 42) ||
						(targetA.basePosition.x === -42 && targetB.basePosition.x === 42) ||
						(targetA.basePosition.x === -42 && targetB.basePosition.x === 21) ||
						(targetA.basePosition.x === -42 && targetB.basePosition.x === 119) ||
						(targetA.basePosition.x === -21 && targetB.basePosition.x === 21) ||
						(targetA.basePosition.x === -21 && targetB.basePosition.x === 42) ||
						(targetA.basePosition.x === 132 && targetB.basePosition.x === 26) ||
						(targetA.basePosition.x === 132 && targetB.basePosition.x === -26) ||
						(targetA.basePosition.x === 65 && targetB.basePosition.x === 45) ||
						(targetA.basePosition.x === 65 && targetB.basePosition.x === 26) ||
						(targetA.basePosition.x === 65 && targetB.basePosition.x === -26) ||
						(targetA.basePosition.x === 65 && targetB.basePosition.x === -45) ||
						(targetA.basePosition.x === 65 && targetB.basePosition.x === -65) ||
						(targetA.basePosition.x === 65 && targetB.basePosition.x === -65) ||
						(targetA.basePosition.x === 45 && targetB.basePosition.x === -45) ||
						(targetA.basePosition.x === 45 && targetB.basePosition.x === -65) ||
						(targetA.basePosition.x === 26 && targetB.basePosition.x === -65) ||
						(targetA.basePosition.x === 26 && targetB.basePosition.x === -132) ||
						(targetA.basePosition.x === -26 && targetB.basePosition.x === 65) ||
						(targetA.basePosition.x === -26 && targetB.basePosition.x === -65) ||
						(targetA.basePosition.x === -26 && targetB.basePosition.x === -132) ||
						(targetA.basePosition.x === -45 && targetB.basePosition.x === -65)) {
					continue;
				}

				//線の透明度を距離に応じて調整
				const dx = vertexA.position.x - vertexB.position.x;
				const dy = vertexA.position.y - vertexB.position.y;
				const dz = vertexA.position.z - vertexB.position.z;
				const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

				if (dist < this.#DISTANCE) {
					vertexA.numConnection++;
					vertexB.numConnection++;

					let alpha = 1.0 - dist / this.#DISTANCE;
					//線の頂点座標を設定
					this.#lineMesh.positions[vertexPos++] = vertexA.position.x;
					this.#lineMesh.positions[vertexPos++] = vertexA.position.y;
					this.#lineMesh.positions[vertexPos++] = vertexA.position.z;
					this.#lineMesh.positions[vertexPos++] = vertexB.position.x;
					this.#lineMesh.positions[vertexPos++] = vertexB.position.y;
					this.#lineMesh.positions[vertexPos++] = vertexB.position.z;

					//線の頂点カラーのアルファを調整（アルファを設定しているようで色の濃さを設定している）
					this.#lineMesh.colors[colorPos++] = alpha;
					this.#lineMesh.colors[colorPos++] = alpha;
					this.#lineMesh.colors[colorPos++] = alpha;
					this.#lineMesh.colors[colorPos++] = alpha;
					this.#lineMesh.colors[colorPos++] = alpha;
					this.#lineMesh.colors[colorPos++] = alpha;

					lineCount++;
				}
			}
		}

		this.#lineMesh.geometry.setDrawRange(0, lineCount * 2); //適宜指定することで、前フレームときの線がリセットされ残らない
		this.#lineMesh.geometry.attributes.position.needsUpdate = true;
		this.#lineMesh.geometry.attributes.color.needsUpdate = true;
		this.#particleMesh.geometry.attributes.position.needsUpdate = true;

		this.#timeDelta++;
	}
}
