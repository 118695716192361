import * as THREE from "../../../threejs/build/three.module";
import {EVENT, PARAMETER} from "../../../const";

export class LineMesh extends THREE.LineSegments {
	positions;
	colors;
	#material;

	constructor(vertexNum) {
		const segment = vertexNum * vertexNum;
		const geometry = new THREE.BufferGeometry();
		const material = new THREE.LineBasicMaterial({
			vertexColors: true,
			blending: THREE.SubtractiveBlending,
			color: 0x888888,
			//transparent: true,
		});
		super(geometry, material);

		this.#material = material;
		this.positions = new Float32Array(segment * 3);
		this.colors = new Float32Array(segment * 3);
		geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3).setUsage(THREE.DynamicDrawUsage));
		geometry.setAttribute('color', new THREE.BufferAttribute(this.colors, 3).setUsage(THREE.DynamicDrawUsage));

		geometry.computeBoundingSphere();
		geometry.setDrawRange(0, segment);

		//色の変更
		document.addEventListener(EVENT.COLOR_TO_WHITE, () => {
			//material.color.setHex(0xcccccc);
			this.#changeBlendingMode(THREE.SubtractiveBlending)
		});
		document.addEventListener(EVENT.COLOR_TO_BLACK, () => {
			//material.color.setHex(0x999999);
			this.#changeBlendingMode(THREE.AdditiveBlending)
		});
	}

	#changeBlendingMode(blendMode) {
		const delay = PARAMETER.COLOR_CHANGE_SEC * 1000;
		setTimeout(() => {
			this.#material.blending = blendMode;
		}, delay);
	}

}
