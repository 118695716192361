import {DeviceInfo} from "./qudan/utility/device-info";
import {UiManager} from "./qudan/ui-manager";
import {UiHomeManager} from "./qudan/ui-home-manager";
import {WebglManager} from "./qudan/webgl-manager";

window.addEventListener("DOMContentLoaded", () => {
	new DeviceInfo();

	const isPc = document.querySelector("html").classList.contains("pc");
	const pageType = document.querySelector("main").id;

	const uiManager = (pageType === "home") ? new UiHomeManager() : new UiManager();
	const webglManager = new WebglManager();

	resizeEventListener();
	scrollEventListener();
	update();

	/*
	 * updateを一括管理
	 */
	function update() {
		TWEEN.update();
		uiManager.update();
		webglManager.update();
		window.requestAnimationFrame(update); //毎フレーム実行
	}

	/*
	 * リサイズイベントを一括管理
	 */
	function resizeEventListener() {
		let resizeTimer;
		let prevWindowWidth = window.innerWidth;
		window.addEventListener("resize", () => {
			if (resizeTimer) clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				if (prevWindowWidth !== window.innerWidth || isPc) {
					uiManager.resize();
					webglManager.resize();
					prevWindowWidth = window.innerWidth;
				}
			}, 100);
		});
	}

	/*
	 * スクロールイベントを一括管理
	 */
	function scrollEventListener() {
		window.addEventListener("scroll", () => {
			uiManager.scroll();
		});
	}

});
