/*
 * ハンバーガーボタンのトグルナビゲーションの管理
 */
export class ToggleNav {

	constructor() {
		const htmlTag = document.querySelector("html");
		const navToggleBtn = document.querySelector("header .toggle");
		const globalNav = document.querySelector("#global-nav");
		const navAnchors = document.querySelectorAll("#global-nav a");

		if (!navToggleBtn) return;

		navToggleBtn.addEventListener("click", function (e) {
			navToggleBtn.classList.toggle("close");
			globalNav.classList.toggle("open");
			htmlTag.classList.toggle("no-scrolling");
		});

		//ナビの中のリンクを押したとき
		for (let i = 0; i < navAnchors.length; i++) {
			navAnchors[i].addEventListener("click", function (e) {
				navToggleBtn.classList.remove("close");
				globalNav.classList.remove("open");
				htmlTag.classList.remove("no-scrolling");
			});
		}
	}

}
