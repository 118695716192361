import * as THREE from "../../../threejs/build/three.module";
import {logoCoords} from "./coordinate/logo-coords";
import {Vertex} from "./vertex";
import {Target} from "./target";
import {EVENT, PARAMETER} from "../../../const";

export class VertexManager {
	vertexes = [];
	targets = [];
	spaceR = 0;
	#currentType = "logo";

	constructor(maxVertexNum, spaceR) {
		this.spaceR = spaceR;

		//ロゴ座標を設定
		let vertexCount = 0;
		for (let i = 0; i < logoCoords.length; i++) {
			const coord = logoCoords[i];

			for (let j = 0; j < 4; j++) {
				const x = coord[0] + Math.random() * 50 - 25;
				const y = coord[1] + Math.random() * 50 - 25;
				const z = j * 50 + Math.random() * 50 - 25;

				//頂点座標
				const vertex = new Vertex();
				vertex.position.set(x, y, z);
				vertex.maxSpeed = 2;
				this.vertexes[vertexCount] = vertex;

				//ターゲットの座標
				const target = new Target();
				target.init(coord[0], coord[1], j * 50);
				target.mode = Target.MODE_WAIT_AND_MOVE;
				this.targets[vertexCount] = target;

				vertexCount++;
			}
		}

		//流星
		for (let i = vertexCount; i < maxVertexNum; i++) {
			const x = Math.random() - 0.5;
			const y = Math.random() - 0.5;
			const z = Math.random() - 0.5;

			//頂点を配置
			const v = new THREE.Vector3(x, y, z);
			v.normalize().multiplyScalar(Math.random() * this.spaceR);
			const vertex = new Vertex();
			vertex.position.set(v.x, v.y, v.z);
			vertex.maxSpeed = Math.random() + 0.1;
			this.vertexes[i] = vertex;

			//ターゲットを反対側の端に配置
			const t = new THREE.Vector3(x, y, z);
			t.normalize().multiplyScalar(-1);
			const a = new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);
			a.normalize().multiplyScalar(0.5); //45度のゆらぎを作るため、ランダムな単位ベクトルをさらに半分を用意
			t.add(a);
			t.normalize().multiplyScalar(spaceR);
			const target = new Target();
			target.init(t.x, t.y, t.z);
			target.mode = Target.MODE_SHOOTING_STAR;
			this.targets[i] = target;

			vertexCount++;
		}

		//形状を変化させる
		document.addEventListener(EVENT.MORPH_TO_LOGO, () => {
			this.changeToLogo();
		});
		document.addEventListener(EVENT.MORPH_TO_SPHERE, () => {
			this.changeToSphere();
		});
		document.addEventListener(EVENT.MORPH_TO_PILLAR, () => {
			this.changeToPillar();
		});
	}

	/**
	 * M字ロゴに変形
	 */
	changeToLogo() {
		if (this.#currentType == "logo") return;

		//ロゴ座標の設定
		let vertexCount = 0;
		for (let i = 0; i < logoCoords.length; i++) {
			const coord = logoCoords[i];
			for (let j = 0; j < 4; j++) {
				const target = this.targets[vertexCount];
				target.init(coord[0], coord[1], j * 50);
				target.mode = Target.MODE_WAIT_AND_MOVE;

				const vertex = this.vertexes[vertexCount];
				vertex.maxSpeed = 2;
				vertexCount++;
			}
		}

		//半分を流星に戻す
		for (let i = vertexCount; i < this.targets.length * 0.5; i++) {
			const x = Math.random() - 0.5;
			const y = Math.random() - 0.5;
			const z = Math.random() - 0.5;

			//ターゲットを反対側の端に配置
			const t = new THREE.Vector3(x, y, z);
			t.normalize().multiplyScalar(-1);
			const a = new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);
			a.normalize().multiplyScalar(0.5); //45度のゆらぎを作るため、ランダムな単位ベクトルをさらに半分を用意
			t.add(a);
			t.normalize().multiplyScalar(this.spaceR);
			const target = this.targets[i];
			target.init(t.x, t.y, t.z);
			target.mode = Target.MODE_SHOOTING_STAR;

			//頂点の移動スピードを遅くする
			const vertex = this.vertexes[i];
			vertex.maxSpeed = Math.random() + 0.1;

			vertexCount++;
		}

		this.#currentType = "logo";
	}

	/**
	 * 球体に変形
	 */
	changeToSphere() {
		if (this.#currentType == "sphere") return;

		//ターゲット座標の半分を球体にする
		for (let i = 0; i < this.targets.length * 0.5; i++) {
			const target = this.targets[i];
			const v = new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);
			v.normalize().multiplyScalar(PARAMETER.SPHERE_R);
			target.init(v.x, v.y, v.z);
			target.mode = Target.MODE_WAIT_AND_MOVE;

			const vertex = this.vertexes[i];
			vertex.maxSpeed = 2;
		}

		this.#currentType = "sphere";
	}

	/**
	 * 円柱に変形
	 */
	changeToPillar() {
		if (this.#currentType == "pillar") return;

		for (let i = 0; i < this.targets.length * 0.5; i++) {
			const x = Math.sin(i * 0.1) * 200;
			const y = Math.random() * 600 - 300;
			const z = Math.cos(i * 0.1) * 200;

			const target = this.targets[i];
			target.init(x, y, z);
			target.mode = Target.MODE_WAIT;

			const vertex = this.vertexes[i];
			vertex.maxSpeed = 2;
		}

		this.#currentType = "pillar";
	}

	/**
	 * 三角錐に変形
	 */
	changeToTriangular() {
	}
}
