/*
 * transition-delay をランダムに割り振る
 */
export class RandomDelay {

	static setRandomTransitionDelay(selectors, interval = 1, delay = 0) {
		const targets = document.querySelectorAll(selectors);
		for (let i = 0; i < targets.length; i++) {
			const d = Math.random() * interval + delay;
			targets[i].style.transitionDelay = d + "s";
		}
	}
}
