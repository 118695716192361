/*
 * デバイス判別
 */
export class DeviceInfo {

	constructor() {
		const u = window.navigator.userAgent.toLowerCase();

		const isMobile = (u.indexOf('windows') != -1 && u.indexOf('phone') != -1)
				|| u.indexOf('iphone') != -1
				|| u.indexOf('ipod') != -1
				|| (u.indexOf('android') != -1 && u.indexOf('mobile') != -1)
				|| (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1)
				|| u.indexOf('blackberry') != -1
				|| u.indexOf('touch') != -1;

		const isTablet = (u.indexOf('windows') != -1 && u.indexOf('touch') != -1)
				|| u.indexOf('ipad') != -1
				|| (u.indexOf('android') != -1 && u.indexOf('mobile') == -1)
				|| (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1)
				|| u.indexOf('kindle') != -1
				|| u.indexOf('silk') != -1
				|| u.indexOf('playbook') != -1;

		const isPC = !isMobile && !isTablet;

		let os;
		if ((u.indexOf('windows') != -1 && u.indexOf('phone') != -1))
			os = 'windows-phone';
		else if (u.indexOf('windows') != -1)
			os = 'windows';
		else if (u.indexOf('iphone') != -1 || u.indexOf('ipod') != -1 || u.indexOf('ipad') != -1)
			os = 'ios';
		else if (u.indexOf('android') != -1)
			os = 'android';
		else if (u.indexOf('mac') != -1)
			os = 'mac';
		else
			os = 'os-ohter';

		let browser;
		if (u.indexOf('msie') != -1 || u.indexOf('trident') != -1)
			browser = 'ie';
		else if (u.indexOf('edge') != -1)
			browser = 'edge';
		else if (u.indexOf('chrome') != -1)
			browser = 'chrome';
		else if (u.indexOf('safari') != -1)
			browser = 'safari';
		else if (u.indexOf('firefox') != -1)
			browser = 'firefox';
		else if (u.indexOf('opera') != -1)
			browser = 'opera';
		else
			browser = 'browser-ohter';

		let type;
		if (isMobile)
			type = 'sp';
		else if (isTablet)
			type = 'tb';
		else if (isPC)
			type = 'pc';

		//console.log(window.navigator.userAgent.toLowerCase());

		//HTMLタグにデバイス情報を埋め込み
		document.getElementsByTagName('html')[0].classList.add(type, os, browser);

		//タブレット用にviewportを書き換え
		// if(isTablet){
		// 	document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1200px');
		// }
	}
}
