import * as THREE from "../../../threejs/build/three.module";
import {EVENT} from "../../../const";

export class ParticleMesh extends THREE.Points {
	positions;

	constructor(vertexNum) {
		const geometry = new THREE.BufferGeometry();
		const material = new THREE.PointsMaterial({
			blending: THREE.SubtractiveBlending,
			color: 0x888888,
			//transparent: true,
			size: 1,
			sizeAttenuation: false
		});
		super(geometry, material);

		this.positions = new Float32Array(vertexNum * 3);
		geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3).setUsage(THREE.DynamicDrawUsage));

		geometry.setDrawRange(0, vertexNum);

		//色の変更
		document.addEventListener(EVENT.COLOR_TO_WHITE, () => {
			material.blending = THREE.SubtractiveBlending;
			//material.color.setHex(0xffffff);
		});
		document.addEventListener(EVENT.COLOR_TO_BLACK, () => {
			material.blending = THREE.AdditiveBlending;
			//material.color.setHex(0x999999);
		});
	}
}
