/*
 * マウスカーソルに追随するオブジェクト
 * 特定のDOM上で変化させる
 */
export class MouseStalker {
	#stalker;
	#halfSize;
	#posX = 0;
	#posY = 0;
	#targetX = 0;
	#targetY = 0;
	#anchors;
	#FRICTION = 0.15;

	constructor(selectors) {

		this.#stalker = document.querySelector("#mouse-stalker");

		if (!this.#stalker) return;

		this.#halfSize = this.#stalker.clientWidth * 0.5;
		this.#anchors = document.querySelectorAll(selectors);

		//指定要素の上に来たら、ストーカー表示、外れたら非表示
		for (let i = 0; i < this.#anchors.length; i++) {
			const anchor = this.#anchors[i];
			anchor.addEventListener('mouseenter', () => {
				this.#stalker.classList.add('active');
			});
			anchor.addEventListener('mouseleave', () => {
				this.#stalker.classList.remove('active');
			});
		}

		//マウスが移動するとき目的座標を設定
		window.addEventListener('mousemove', event => {
			this.#targetX = event.clientX;
			this.#targetY = event.clientY;
		})
	}

	/*
	 * 毎フレーム実行　ルートのクラスでまとめて実行する
	 */
	update() {
		if (!this.#stalker) return;
		
		this.#posX += (this.#targetX - this.#posX) * this.#FRICTION;
		this.#posY += (this.#targetY - this.#posY) * this.#FRICTION;
		this.#stalker.style.left = this.#posX - this.#halfSize + 'px';
		this.#stalker.style.top = this.#posY - this.#halfSize + 'px';
	}
}
