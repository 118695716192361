import * as THREE from "../threejs/build/three.module";

import Stats from "../threejs/examples/jsm/libs/stats.module";
import {GUI} from "../threejs/examples/jsm/libs/lil-gui.module.min";

import {CanvasResizer} from "webgl/canvas-resizer";
import {CameraMover} from "webgl/camera-mover";
import {MorphLogo} from "webgl/morph/morph-logo";
import {BgColorChanger} from "webgl/color/bg-color-changer";

import {EventDispatcher} from "utility/event-dispatcher";
import {EVENT} from "../const";

export class WebglManager {
	#renderer;
	#camera;
	#scene;
	#canvasResizer
	#cameraMover
	#stats;
	#logo
	#bgColorChanger;
	#debugMode;

	constructor() {
		this.#renderer = new THREE.WebGLRenderer({
			canvas: document.querySelector('#webgl-canvas'),
			antialias: false,
			outputEncoding: THREE.sRGBEncoding,
			//alpha: true,
		});

		this.#debugMode = document.querySelector('#webgl-canvas').dataset.debug;

		this.#scene = new THREE.Scene();
		this.#scene.background = new THREE.Color(0xf8f8f8);

		this.#camera = new THREE.PerspectiveCamera(30, 1.0, 0.1, 10000);
		this.#canvasResizer = new CanvasResizer(this.#renderer, this.#camera);
		this.#cameraMover = new CameraMover(this.#camera, this.#renderer, this.#debugMode);

		//変形するロゴ
		this.#logo = new MorphLogo();
		this.#scene.add(this.#logo);

		//背景色の変更
		this.#bgColorChanger = new BgColorChanger(this.#scene);

		if (this.#debugMode) this.#setGUI();

		//Homeは背景色を黒に
		if (document.querySelector("main").id == "home" || this.#debugMode) {
			EventDispatcher.dispatch(EVENT.COLOR_TO_BLACK);
		}

		//ウィンドウサイズのリサイズ対応

		this.update();
	}

	/*
	 * フレーム更新
	 */
	update() {
		this.#logo.update();
		this.#cameraMover.update();
		this.#renderer.render(this.#scene, this.#camera);
		if (this.#debugMode) this.#stats.update();
	}

	/*
	 * リサイズ時の処理
	 */
	resize() {
		this.#cameraMover.resize();
		this.#canvasResizer.resize();
	}

	/*
	 * 開発用のGUIを表示
	 */
	#setGUI() {
		//スタッツ表示
		this.#stats = new Stats();
		this.#stats.showPanel(0);
		document.body.appendChild(this.#stats.dom);

		//GUI
		const gui = new GUI();

		//背景色と線の色を変更
		const colorFolder = gui.addFolder('BG Color');
		const colorParams = {
			white: () => {
				EventDispatcher.dispatch(EVENT.COLOR_TO_WHITE);
			},
			black: () => {
				EventDispatcher.dispatch(EVENT.COLOR_TO_BLACK);
			}
		};
		colorFolder.add(colorParams, 'white');
		colorFolder.add(colorParams, 'black');

		//形状を変化させるボタン
		const shapeFolder = gui.addFolder('Change Shape');
		//shapeFolder.close();
		const shapeParams = {
			logo: () => {
				EventDispatcher.dispatch(EVENT.MORPH_TO_LOGO);
			},
			sphere: () => {
				EventDispatcher.dispatch(EVENT.MORPH_TO_SPHERE);
			},
			pillar: () => {
				EventDispatcher.dispatch(EVENT.MORPH_TO_PILLAR);
			},
			triangular: () => {
				EventDispatcher.dispatch(EVENT.MORPH_TO_TRIANGULAR);
			},
		};
		shapeFolder.add(shapeParams, 'logo');
		shapeFolder.add(shapeParams, 'sphere');
		shapeFolder.add(shapeParams, 'pillar');
		//shapeFolder.add(shapeParams, 'triangular');
	}

}
