import * as THREE from "../../../threejs/build/three.module";

/**
 * 頂点オブジェクトの移動先座標
 */
export class Target {
	#MAX_MOVE_RANGE = 50;

	#position = new THREE.Vector3();
	#basePosition = new THREE.Vector3();
	#r = 0;
	#radian = 0;
	#idlingTimeLimit = 0;
	#idlingTimeCount = 0;

	mode;
	static MODE_WAIT_AND_MOVE = 'MODE_WAIT_AND_MOVE';
	static MODE_WAIT = 'MODE_WAIT';
	static MODE_SHOOTING_STAR = 'MODE_SHOOTING_STAR';

	get position() {
		return this.#position;
	}

	get basePosition() {
		return this.#basePosition;
	}

	constructor() {
	}

	/**
	 * 初期化
	 * 形状を変化させるときに設定しなおす
	 * @param x
	 * @param y
	 * @param z
	 * @param r
	 * @param radian
	 */
	init(x, y, z) {
		this.#position.set(x, y, z);
		this.#basePosition.set(x, y, z);
		this.#idlingTimeLimit = Math.random() * 60 + 60;
	}

	/**
	 * ターゲット座標の移動
	 * @param timeDelta
	 */
	update(timeDelta) {
		switch (this.mode) {
			case Target.MODE_WAIT_AND_MOVE:
				this.#waitAndMove(timeDelta);
				break;
			case Target.MODE_WAIT:
				break;
		}
	}

	/**
	 * 静止と移動を繰り返す
	 * @param timeDelta
	 */
	#waitAndMove(timeDelta) {
		if (this.#idlingTimeCount == 0) {
			const x = Math.random() - 0.5;
			const y = Math.random() - 0.5;
			const z = Math.random() - 0.5;
			const velocity = new THREE.Vector3(x, y, z);
			velocity.multiplyScalar(Math.random() * this.#MAX_MOVE_RANGE);
			this.#position.set(this.#basePosition.x, this.#basePosition.y, this.#basePosition.z);
			this.#position.add(velocity);
		}

		this.#idlingTimeCount++;

		if (this.#idlingTimeCount > this.#idlingTimeLimit) {
			this.#idlingTimeCount = 0;
		}
	}
}
