import scrollama from "../plugin/scrollama";
import {MouseStalker} from "ui/mouse-stalker";
import {ToggleNav} from "ui/toggle-nav";
import {FirstHeight} from "ui/first-height";

export class UiManager {
	#mouseStalker;
	#baseScroller;

	constructor() {
		//高さ設定
		FirstHeight.setProperty();

		//ハンバーガーメニュー
		new ToggleNav();

		//マウスに追従する
		this.#mouseStalker = new MouseStalker("a.detail");

		//スクロールに応じた表示チェック
		this.#baseScroller = scrollama();
		this.#baseScroller
				.setup({step: "article, section", offset: 0.8, once: true, debug: false})
				.onStepEnter((response) => {
					response.element.classList.add("inview");
				});
	}

	/*
	 * フレーム更新
	 */
	update() {
		this.#mouseStalker.update();
	}

	/*
	 * スクロール時の処理
	 */
	scroll() {
		//ポリモーフィズム的に使っているので、空でもメソッドを残しておく
	}

	/*
	 * リサイズ時の処理
	 */
	resize() {
		FirstHeight.setProperty();
		this.#baseScroller.resize();
	}
}
