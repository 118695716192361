import * as THREE from "../../../threejs/build/three.module";

/**
 * ベクトルで自律移動する座標
 */
export class Vertex {
	position = new THREE.Vector3();
	velocity = new THREE.Vector3(); //速度ベクトル
	mass = 1; //重さ
	maxSpeed = 2; //最大速度

	#maxForce = 1; //最大操舵力
	#steeringForce = new THREE.Vector3(); //旋回ベクトル
	#slowdownThresholdSQ = 200;　//減速する閾値
	#arrivedThresholdSQ = 10; //到着したと判断する閾値

	//経路追跡用のパラメータ
	pathIndex = 0;
	#pathThresholdSQ = 10;

	//他の点と接続している数
	numConnection = 0;

	constructor() {
	}

	/**
	 * 運動ベクトルを位置に反映させる
	 */
	update() {
		//最大操舵力以下に切り詰め
		this.#steeringForce.clampLength(0, this.#maxForce);
		//質量が重いと旋回力がその分下がる（これが加速度となる）
		this.#steeringForce.divideScalar(this.mass);
		//速度に操舵力（加速度）を足し合わせる
		this.velocity.add(this.#steeringForce);
		//次回に備えてリセット
		this.#steeringForce.set(0, 0, 0);

		//速度は最高スピード以内に収めること
		this.velocity.clampLength(0, this.maxSpeed);
		//速度を位置に足す
		this.position.add(this.velocity);
	}

	/**
	 * 追求行動：特定の座標に移動させる
	 * @param targetPosition THREE.Vector3D
	 */
	seek(targetPosition) {
		const desiredVelocity = new THREE.Vector3();
		desiredVelocity.subVectors(targetPosition, this.position);
		desiredVelocity.normalize();
		desiredVelocity.multiplyScalar(this.maxSpeed);

		const force = new THREE.Vector3();
		force.subVectors(desiredVelocity, this.velocity);
		this.#steeringForce.add(force);
	}

	/**
	 * 到着行動：特定の点に移動（減速しながら近づく）
	 * @param targetPosition THREE.Vector3D
	 */
	arrive(targetPosition) {
		const desiredVelocity = new THREE.Vector3();
		desiredVelocity.subVectors(targetPosition, this.position);
		desiredVelocity.normalize();

		const distSQ = this.position.distanceToSquared(targetPosition);

		if (distSQ < this.#arrivedThresholdSQ) {
			return true;
		}

		if (distSQ > this.#slowdownThresholdSQ) {
			desiredVelocity.multiplyScalar(this.maxSpeed);
		} else {
			desiredVelocity.multiplyScalar(this.maxSpeed * distSQ / this.#slowdownThresholdSQ);
		}

		const force = new THREE.Vector3();
		force.subVectors(desiredVelocity, this.velocity);
		this.#steeringForce.add(force);

		return false;
	}

	/**
	 * 経路追跡：複数の目的地へ順番に移動する
	 * @param path 目的地（THREE.Vector3D）の配列
	 * @param loop ループするかどうかのBool値
	 */
	followPath(path, loop = true) {
		const wayPoint = path[this.pathIndex];

		//距離の閾値内にあるかどうか
		if (this.position.distanceToSquared(wayPoint) < this.#pathThresholdSQ) {
			if (this.pathIndex >= path.length - 1) {
				if (loop) {
					this.pathIndex = 0;
				}
			} else {
				this.pathIndex++;
			}
		}

		if (this.pathIndex >= path.length - 1 && !loop) {
			this.arrive(wayPoint);
		} else {
			this.seek(wayPoint);
		}
	}
}
