
/**
 * キャンバスのサイズを調整
 */
export class CanvasResizer {
	#renderer;
	#camera;

	constructor(renderer, camera) {
		this.#renderer = renderer;
		this.#camera = camera;

		this.resize();
	}

	/*
	 * ウィンドウサイズに応じて、レンダラとカメラを調整
	 */
	resize() {
		const width = window.innerWidth;
		const height = window.innerHeight;

		//レンダラのサイズを調整
		this.#renderer.setSize(width, height);
		//解像度を調整
		const pixel = (window.devicePixelRatio > 2) ? 2 : window.devicePixelRatio;
		this.#renderer.setPixelRatio(pixel);

		//カメラのアスペクト比を正す
		this.#camera.aspect = width / height;
		this.#camera.updateProjectionMatrix();
	}
}
