/*
 * scrollama をトリガーにして Videoの再生・停止をコントロール
 */
export class VideoController {

	#heroVideos;
	#everStopVideo; //dev用にビデオを静止しておくフラグ

	constructor() {
		this.#heroVideos = document.querySelectorAll("#hero video");
		if (location.search.indexOf("stopvideo") !== -1) {
			this.#everStopVideo = true;
		}
	}

	/*
	 * #heroの動画再生・停止
	 */
	heroPlay() {
		if (this.#everStopVideo) return;

		for (let i = 0; i < this.#heroVideos.length; i++) {
			this.#heroVideos[i].play();
		}
	}

	heroStop() {
		if (this.#everStopVideo) return;

		setTimeout(() => {
			for (let i = 0; i < this.#heroVideos.length; i++) {
				this.#heroVideos[i].pause();
			}
		}, 1000);
	}

	/*
	 * WORKSの動画再生・停止
	 */
	worksPlay(element) {
		if (this.#everStopVideo) return;

		const video = element.querySelector("video");
		if (video) {
			video.play();
		}
	}

	worksStop(element) {
		if (this.#everStopVideo) return;

		const video = element.querySelector("video");
		if (video) {
			video.pause();
		}
	}
}
