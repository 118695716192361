import {EVENT, PARAMETER} from "../../../const";
import * as THREE from "../../../threejs/build/three.module";

/*
 * 背景色を変更する
 */
export class BgColorChanger {
	#scene;

	constructor(scene) {
		this.#scene = scene;
		document.addEventListener(EVENT.COLOR_TO_WHITE, () => {
			this.#changeMonoColor(0.973);
		});

		document.addEventListener(EVENT.COLOR_TO_BLACK, () => {
			this.#changeMonoColor(0.066);
		});
	}

	#changeMonoColor(targetNum) {
		let color = {k: 0};
		color.k = this.#scene.background.r;
		const duration = PARAMETER.COLOR_CHANGE_SEC * 1000;

		new TWEEN.Tween(color)
				.to({k: targetNum}, duration)
				.easing(TWEEN.Easing.Cubic.In)
				.onUpdate(() => {
					this.#scene.background = new THREE.Color(color.k, color.k, color.k);
				})
				.start();
	}
}
