/*
 * ロゴの頂点座標 [x, y]
 */
export const logoCoords = [
	[-200, 185],
	[-119, 185],
	[-42, 185],
	[-21, 115],
	[0, 45],
	[21, 115],
	[42, 185],
	[119, 185],

	[200, 185],
	[200, 111],
	[200, 37],
	[200, -37],
	[200, -111],
	[200, -185],

	[132, -185],
	[65, -185],
	[65, -118],
	[64, -52],
	[45, -118],
	[26, -185],
	[-26, -185],
	[-45, -118],
	[-64, -52],
	[-65, -118],
	[-65, -185],
	[-132, -185],

	[-200, -185],
	[-200, -111],
	[-200, -37],
	[-200, 37],
	[-200, 111],
];
