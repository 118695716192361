import * as THREE from "../../threejs/build/three.module";
import {OrbitControls} from "../../threejs/examples/jsm/controls/OrbitControls";

/**
 * カメラを動かす
 */
export class CameraMover {
	#camera
	#rotationX = 0; //カメラの角度
	#rotationY = 0;
	#ROTATION_MAX_DEG = 60;
	#DISTANCE = 1200;
	#diff;
	#MIN_DIFF = -100;
	#BASE_WINDOW_SIZE = 1000;
	#mouseX = window.innerWidth * 0.5; //マウス座標（開始時は中央に）
	#mouseY = window.innerHeight * 0.5;

	constructor(camera, renderer, debugMode) {
		this.#camera = camera;

		this.#camera.position.set(0, 0, this.#DISTANCE);
		this.resize();

		if (debugMode) {
			//暫定的にOrbitControlsを使う
			//const controls = new OrbitControls(camera, renderer.domElement);
		}

		//マウス座標に応じた処理
		document.addEventListener('mousemove', (event) => {
			this.#mouseX = event.clientX;
			this.#mouseY = event.clientY;
		});

		document.body.addEventListener('mouseleave', () => {
			this.#mouseX = window.innerWidth * 0.5;
			this.#mouseY = window.innerHeight * 0.5;
		});
	}

	/*
	 * ウィンドウサイズに応じて、カメラの距離を調整
	 */
	resize() {
		const diffHeight = (window.innerHeight - this.#BASE_WINDOW_SIZE) * 1.1;
		const diffWidth = (this.#BASE_WINDOW_SIZE - window.innerWidth) * 1.6;
		this.#diff = diffHeight + diffWidth;

		if (this.#diff < this.#MIN_DIFF) {
			this.#diff = this.#MIN_DIFF;
		}
	}

	/*
	 * マウス位置に応じてカメラの座標を変更
	 */
	update() {
		//マウスの座標とウィンドウ幅から回転する値を求める
		const targetRotationX = (this.#mouseX / window.innerWidth) * this.#ROTATION_MAX_DEG - this.#ROTATION_MAX_DEG * 0.5;
		const targetRotationY = (this.#mouseY / window.innerHeight) * this.#ROTATION_MAX_DEG - this.#ROTATION_MAX_DEG * 0.5;

		//イージングで滑らかに回転させる
		this.#rotationX += (targetRotationX - this.#rotationX) * 0.02;
		this.#rotationY += (targetRotationY - this.#rotationY) * 0.02;

		//ラジアンに変換する
		const radianX = (this.#rotationX * Math.PI) / 180;
		const radianY = (this.#rotationY * Math.PI) / 180;

		//カメラ位置を変更
		this.#camera.position.x = (this.#DISTANCE + this.#diff) * Math.sin(radianX);
		this.#camera.position.z = (this.#DISTANCE + this.#diff) * Math.cos(radianX);

		this.#camera.position.y = (this.#DISTANCE + this.#diff) * Math.sin(radianY);
		this.#camera.position.z = (this.#DISTANCE + this.#diff) * Math.cos(radianY);

		this.#camera.lookAt(new THREE.Vector3(0, 0, 0));
	}
}
