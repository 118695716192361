/**
 * 定数
 */

export class EVENT {
	static MORPH_TO_LOGO = 'MORPH_TO_LOGO';
	static MORPH_TO_SPHERE = 'MORPH_TO_SPHERE';
	static MORPH_TO_PILLAR = 'MORPH_TO_PILLAR';
	static MORPH_TO_TRIANGULAR = 'MORPH_TO_TRIANGULAR';

	static COLOR_TO_WHITE = 'COLOR_TO_WHITE';
	static COLOR_TO_BLACK = 'COLOR_TO_BLACK';
}

export class PARAMETER {
	static COLOR_CHANGE_SEC = 0.5; //色を変えるときの秒数
	static SPHERE_R = 250; //球状のときの半径
}
